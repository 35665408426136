import React, { Component } from 'react';

class FiftyOff extends Component {
  render() {
    return (
      <div className="fifty-off">30 %</div>
    );
  }
}

export default FiftyOff;
