import React, { Component } from 'react';
import Header from '../header';
import Nav from '../nav';
import Footer from '../footer';

class Announcements extends Component {
  render() {
    return (
      <div className="container-fluid">
        <Header />
        <Nav />
        <div className="row content">
          <div className="col-md content-inner">
            <h1>
              Naslovi u pripremi
            </h1>
            <hr />
          </div>
       </div>
        <div className="row">
          <div className="col-md">
            <h3 className="important-h"><strong></strong></h3>
			<p className="lead"><strong></strong></p>
              <p className="lead"></p>
            <p> </p>
            <p className="lead"><strong></strong></p>
            <p className="paragraph-margin">
              <img className="wide-img" src="http://litteris.com.hr/images/blank.jpg" alt=" " />
            </p>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Announcements;