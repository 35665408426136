import React, { Component } from 'react';

class Footer extends Component {
  render() {
    return (
      <div className="row footer">
        <div className="col-md">
          <hr />
          &copy; Litteris
        </div>
      </div>
    );
  }
}

export default Footer;
